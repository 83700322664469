<template>
  <b-modal
    id="team-modal"
    ref="my-modal"
    :title="$t('TEAM')"
    :ok-title="group.groupID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col
          v-show="!canAddTeam"
          cols="12"
        >
          <b-alert
            variant="info"
            show
          >
            <div class="alert-body">
              <span>
                {{ teamMessage }}
              </span>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-form-group
            :label="$t('TEAM_NAME')"
            label-for="id-name"
            :state="nameState"
            :invalid-feedback="$t('TEAM_REQUIRED')"
          >
            <b-form-input
              id="id-name"
              v-model="group.groupname"
              :state="nameState"
              :placeholder="$t('TEAM_NAME')"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            :label="$t('COACHING_TEAM')"
            label-for="id-coaching"
            :state="nameState"
          >
            <b-form-checkbox
              id="checkbox-coaching"
              v-model="group.groupcomplevel"
              value="-2"
              unchecked-value="0"
              class="mt-1"
              name="checkbox-coaching"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, VBModal,
  BRow, BCol, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BFormCheckbox,
    BAlert,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'editGroup',
  },
  props: {
    competition: {
      type: Object,
      required: true,
    },
    editGroup: {
      type: Object,
      default: () => {},
    },
    fetchGroup: {
      type: Function,
      required: true,
    },
    canAddTeam: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      group: {
        groupID: 0,
        groupcomplevel: 0,
        competitions: 0,
        companyID: 0,
        B: 0,
        G: 0,
        R: 0,
        groupname: '',
        competition_group: {},
      },
      gameTypes: constants.GAME_TYPE,
      teamMessage: this.$i18n.t('MESSAGE.EVERYONE_SAME_TEAM'),
      nameState: null,
    }
  },
  watch: {
    editGroup: {
      handler() {
        if (this.editGroup) {
          this.group = this.editGroup
        }
      },
    },
  },
  setup() {
    const {
      successMessage,
      errorMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('game', ['createGroup', 'updateGroup']),
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    resetModal() {
      this.nameState = null
      this.group = {}
      this.$bvModal.hide('team-modal')
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      if (this.group.groupID) {
        this.updateGroup(this.group).then(response => {
          if (response) {
            this.resetModal()
            this.successMessage(this.$i18n.t('MESSAGE.TEAM_UPDATED'))
            this.fetchGroup(this.competition.encrypt_id)
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      } else {
        if ((!this.canAddTeam) && this.group.groupcomplevel === 0) {
          this.successMessage(this.teamMessage)
          return
        }
        this.group.competitions = this.competition.ID
        this.group.companyId = this.competition.companyID
        this.createGroup(this.group).then(response => {
          if (response) {
            this.resetModal()
            this.successMessage(this.$i18n.t('MESSAGE.TEAM_CREATED'))
            this.fetchGroup(this.competition.encrypt_id)
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
