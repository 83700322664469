<template>
  <b-modal
    id="new-player-modal"
    ref="my-modal"
    :title="$t('NEW_PLAYERS')"
    cancel-variant="outline-secondary"
    @show="resetModal"
    @hidden="resetModal"
  >
    <!-- Search -->
    <b-row>
      <b-col
        cols="12"
      >
        <b-form-input
          v-model="searchQuery"
          :placeholder="$t('SEARCH')"
        />
      </b-col>
    </b-row>
    <!-- Players  -->
    <div
      v-for="(user,index) in filterdUsers"
      :key="user.userID"
      class="d-flex justify-content-start align-items-center"
      :class="index == 0 ? 'mt-2' : 'mt-1'"
    >
      <feather-icon
        icon="UserIcon"
        size="24"
        class="mr-50 text-success"
      />
      <div class="user-page-info">
        <h6 class="mb-0">
          {{ user.firstname }} {{ user.lastname }}
        </h6>
        <small class="text-muted">{{ user.email }}</small>
      </div>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="text-success"
        class="btn-icon ml-auto"
        size="sm"
      >
        <feather-icon
          icon="UserPlusIcon"
          class="text-success"
          @click="handleSubmit(user)"
        />
      </b-button>
    </div>
    <!--/ Players  -->

  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BButton, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BModal, BButton, BRow, BCol, BFormInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'addGroup',
  },
  props: {
    allUser: {
      type: Number,
      required: true,
    },
    competition: {
      type: Object,
      required: true,
    },
    fetchGroup: {
      type: Function,
      required: true,
    },
    addGroup: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      users: [],
      filterdUsers: [],
      searchQuery: '',
    }
  },
  watch: {
    addGroup: {
      handler() {
        if (this.addGroup) {
          if (this.competition.ID > 0) {
            const payLoad = {
              id: this.competition.ID,
              isAll: this.allUser,
            }
            this.fetchUsers(payLoad)
            this.searchQuery = ''
          }
        }
      },
    },
    searchQuery: {
      handler() {
        if (this.searchQuery) {
          const queryLowered = this.searchQuery.toLowerCase()
          this.filterdUsers = this.users.filter(
            user => (user.firstname.toLowerCase().includes(queryLowered) || user.lastname.toLowerCase().includes(queryLowered)),
          )
        }
      },
    },
  },

  setup() {
    const {
      successMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
    }
  },
  methods: {
    ...mapActions('game', ['fetchAvailableUsers', 'createGroupUser']),
    fetchUsers(data) {
      this.fetchAvailableUsers(data).then(response => {
        if (response) {
          this.users = response.data
          this.filterdUsers = this.users.slice(0, 10)
        }
      }).catch(() => {
        this.showErrorMessage()
      })
    },
    handleSubmit(data) {
      if (data.userID) {
        this.createGroupUser({ user: data, group: this.addGroup, competitionId: this.competition.ID }).then(response => {
          if (response) {
            this.resetModal()
            this.fetchGroup(this.competition.encrypt_id)
            this.successMessage(this.$i18n.t('MESSAGE.USER_ADDED'))
          }
        })
      }
    },
    resetModal() {
      this.group = {}
      this.$bvModal.hide('new-player-modal')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
