<template>
  <b-card
    v-if="groupUsers.length>0"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="groupUsers"
      responsive
      :fields="userTableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.firstname }} {{ data.item.lastname }}</span>
        </div>
      </template>

      <!-- email -->
      <template #cell(email)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.email }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Team -->
      <template #cell(team)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.groupname }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- role -->
      <template #cell(role)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveTeamRole(data.item.groupcomplevel).Icon"
            size="18"
            class="mr-50"
            :class="`text-${resolveTeamRole(data.item.groupcomplevel).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ resolveTeamRole(data.item.groupcomplevel).name }}</span>
        </div>
      </template>
      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant(data.item.record_status).variant}`"
          class="text-capitalize"
        >
          {{ resolveUserStatusVariant(data.item.record_status).name }}
        </b-badge>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.group_user.userID}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mx-1"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.group_user.userID }})"
          />
          <feather-icon
            v-if="canEditTeam"
            :id="`invoice-row-${data.item.group_user.userID}-preview-icon`"
            icon="MailIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="showMessageEmailInvitation(data.item)"
          />
          <feather-icon
            v-if="canEditTeam"
            :id="`game-row-${data.item.group_user.ID}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer mr-1"
            @click="deleteUser(data.item)"
          />
          <feather-icon
            v-if="canEditTeam && data.item.record_status===0"
            :id="`game-row-${data.item.group_user.ID}-preview-icon`"
            :icon="data.item.record_status===0 ? 'ToggleLeftIcon':'ToggleRightIcon'"
            size="16"
            class="cursor-pointer mr-1"
            @click="activateUser(data.item)"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { BCard, BTable, BBadge } from 'bootstrap-vue'
import { mapActions } from 'vuex'
// eslint-disable-next-line import/named
import { resolveTeamRole, resolveUserStatusVariant } from '@/constants/utils'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
  },
  props: {
    groupUsers: {
      type: Array,
      default: () => [],
    },
    canEditTeam: {
      type: Boolean,
      default: () => true,
    },
    fetchGroup: {
      type: Function,
      required: true,
    },
    competition: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const userTableColumns = [
      { key: 'Name' },
      { key: 'Email' },
      { key: 'Team' },
      { key: 'Role' },
      { key: 'Status' },
      { key: 'Actions' },
    ]

    const {
      successMessage, showErrorMessage,
    } = mixinAlert()

    return {
      userTableColumns,
      resolveTeamRole,
      successMessage,
      showErrorMessage,
      resolveUserStatusVariant,
    }
  },
  methods: {
    ...mapActions('game', ['deleteGroupUser', 'sendInvitationEmail']),
    ...mapActions('group', ['updateGroupUser']),
    activateUser(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.ACTIVATED_CONFIRMATION')} ${data.firstname}  ${data.lastname} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            // eslint-disable-next-line no-param-reassign
            data.group_user.record_status = constants.RECORD_STATUS.ACTIVE
            this.updateGroupUser(data.group_user).then(response => {
              if (response) {
                this.fetchGroup(this.competition.encrypt_id)
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_ACTIVATED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    deleteUser(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.firstname}  ${data.lastname} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteGroupUser(data.group_user).then(response => {
              if (response) {
                this.fetchGroup(this.competition.encrypt_id)
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_DELETED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
    showMessageEmailInvitation(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')}  ${data.firstname}  ${data.lastname} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.sendInvitationEmail({ user: data, competition: this.competition }).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.PLAYER_INVITED'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        })
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
